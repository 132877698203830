import React, { useState, useEffect } from "react";
import useTable from "../../../hooks/useTable";
import SingleTaskBoardView from "./SingleTaskBoardView";
import { Box, TableBody, Typography } from "@mui/material";
import {
  getBoardViewData,
  getProjectTasks,
} from "../../../api/project/Project";
import AddNewTaskModal from "./AddNewTaskModal";
import useLoader from "../../../hooks/useLoader";
// import VideoFloatButton from "../../VideoFloatButton";
const headCells = [
  { id: "name", label: "TASK NAME" },
  {
    id: "workInProgress",
    label: "	WORK IN PROGRESS",
    disableSorting: true,
    align: "center",
  },
  { id: "todo", label: "TO DO", disableSorting: true, align: "center" },
  { id: "complete", label: "COMPLETE", disableSorting: true, align: "center" },
  { id: "onHold", label: "ON HOLD", disableSorting: true, align: "center" },
  { id: "closed", label: "CLOSED", disableSorting: true, align: "center" },
];

const AllTasksBoardView = ({
  selectedProject,
  showAddTaskModal,
  setShowAddTaskModal,
}) => {
  const [boardViewData, setBoardViewData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { loaderState, loaderStart, loaderEnd } = useLoader();

  useEffect(async () => {
    const res = await getBoardViewData(selectedProject.id);
    if (res.data.success) {
      setBoardViewData(res.data.data.boardViewTaskData);
    }
  }, []);

  const getTaskList = async () => {
    loaderStart();
    const res = await getProjectTasks({
      projectId: selectedProject.id,
      page: currentPage,
    });
    loaderEnd();
  };

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    boardViewData,
    headCells
  );
  return (
    <Box sx={{ pt: 3 }}>
      {showAddTaskModal && (
        <AddNewTaskModal
          selectedProject={selectedProject}
          showAddTaskModal={showAddTaskModal}
          setShowAddTaskModal={setShowAddTaskModal}
          getTaskList={getTaskList}
        />
      )}
      {boardViewData?.length > 0 ? (
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((task, index) => {
              return (
                <SingleTaskBoardView task={task} key={index} index={index} />
              );
            })}
          </TableBody>
        </TblContainer>
      ) : (
        <Typography align="center" sx={{ p: 4 }} variant="h6" color="secondary">
          {loaderState ? "Loading..." : "Add task to see the Board View"}
        </Typography>
      )}
    </Box>
  );
};

export default AllTasksBoardView;
