import { Grid, TextField } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";

const ProjectDateRange = ({
  selectedProject,
  monthWiseBudgets,
  setMonthWiseBudgets,
  projectStartDate,
  setProjectStartDate,
  projectDueDate,
  setProjectDueDate,
}) => {
  const initialDates = {
    startDate: moment(selectedProject?.start_date),
    dueDate: moment(selectedProject?.due_date),
  };
  const handleStartDateChange = (date) => {
    setProjectStartDate(date);
    const currentStartDate = moment(date);
    if (
      initialDates.startDate.format("YYYY-MM") !==
      currentStartDate.format("YYYY-MM")
    ) {
      const monthDiff = monthWiseBudgets.length - 1;
      const currentDueDate = currentStartDate.clone().add(monthDiff, "month");
      const tempDate = currentStartDate.clone();
      const budgetData = monthWiseBudgets;
      let tempBudgets = [];
      let i = 0;
      while (
        currentDueDate > tempDate ||
        tempDate.format("M") === currentDueDate.format("M")
      ) {
        tempBudgets.push({
          ...budgetData[i],
          date: tempDate.format("YYYY-MM"),
        });
        i++;
        tempDate.add(1, "month");
      }
      setMonthWiseBudgets(tempBudgets);
      setProjectDueDate(currentDueDate);
    }
  };

  const handleProjectDueDateChange = (date) => {
    const currentStartDate = moment(projectStartDate);
    const currentDueDate = moment(date);
    setProjectDueDate(date);

    if (
      initialDates.startDate.format("YYYY-MM") !==
        currentStartDate.format("YYYY-MM") ||
      initialDates.dueDate.format("YYYY-MM") !==
        currentDueDate.format("YYYY-MM")
    ) {
      const tempDate = currentStartDate.clone();
      const budgetData = monthWiseBudgets;
      let tempBudgets = [];
      while (
        currentDueDate > tempDate ||
        tempDate.format("M") === currentDueDate.format("M")
      ) {
        const matchPrevDates = budgetData.filter(
          (obj) => obj?.date === tempDate.format("YYYY-MM")
        );
        if (matchPrevDates?.length) {
          tempBudgets.push(matchPrevDates[0]);
        } else {
          tempBudgets.push({
            date: tempDate.format("YYYY-MM"),
            budget: 0,
            actual: 0,
            note: "",
          });
        }
        tempDate.add(1, "month");
      }
      setMonthWiseBudgets(tempBudgets);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <DatePicker
          label="Project Start Date "
          required
          autoOk={true}
          className="pt-dtpicker"
          fullWidth
          onChange={handleStartDateChange}
          value={projectStartDate}
          format="MM/DD/YYYY"
          name="projectStartDate"
          minDate={initialDates.startDate}
          renderInput={(inputProps) => (
            <TextField
              className="pt-datepicker-ip"
              fullWidth
              variant="outlined"
              {...inputProps}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <DatePicker
          label="Project End Date "
          required
          autoOk={true}
          className="pt-dtpicker"
          fullWidth
          onChange={handleProjectDueDateChange}
          value={projectDueDate}
          name="projectDueDate"
          minDate={projectStartDate}
          renderInput={(inputProps) => (
            <TextField
              className="pt-datepicker-ip"
              fullWidth
              variant="outlined"
              {...inputProps}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectDateRange;
