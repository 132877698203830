import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DatePicker from "@mui/lab/DatePicker";
import axios from "axios";
import { Field, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import * as Yup from "yup";
import currencyMaskOptions from "../../../@const/currencyMaskOptions";
import portfolioSize from "../../../@const/portfolioSize";
import portfolioStatus from "../../../@const/portfolioStatus";
import useCommons from "../../../hooks/useCommons";
import usePortfolio from "../../../hooks/usePortfolio";
import { getNumbers } from "../../../utils/getNumber";
import CustomAutocomplete from "../../CustomAutocomplete";
import CommonAddMemberModal from "../CommonAddMemberModal";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(5),
  },
}));

const CreatePortfolioForm = (props) => {
  const [allPortfolioTypes, setAllPortfolioTypes] = useState([]);
  const [portFolioTypeData, setPortFolioTypeData] = useState("");
  const [newPortfolioName, setNewPortfolioName] = useState("");
  const [portfolioCreatedOn, setPortfolioCreatedOn] = useState(new Date());
  const [taskAssignedTo, setTaskAssignedTo] = useState(null);
  const [blurActive, setBlurActive] = useState(false);

  const {
    showAddMemberModal,
    dropdownMemberList,
    setShowAddMemberModal,
    getDropdownMemberList,
  } = useCommons();

  const { getPortfolioCount } = usePortfolio();
  const navigate = useNavigate();
  const classes = useStyles();

  const currencyMask = createNumberMask({
    ...currencyMaskOptions,
  });

  const handlePortFolioType = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/portfolio/type`,
        {
          type_name: newPortfolioName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setNewPortfolioName("");
      if (response?.data?.success) {
        getPortfolioType();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getPortfolioType = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/portfolio/type`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response?.data?.success) {
        setAllPortfolioTypes(response.data.data.portfolioTypes);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getPortfolioType();
    if (dropdownMemberList.length === 0) {
      getDropdownMemberList();
    }
  }, []);

  const handleProtfolioTypeChange = (e) => {
    e.preventDefault();
    setPortFolioTypeData(e.target.value);
  };

  const handleUChange = (e, val) => {
    setTaskAssignedTo(val?.id || "");
  };

  return (
    <>
      {showAddMemberModal && (
        <CommonAddMemberModal
          showAddMemberModal={showAddMemberModal}
          setShowAddMemberModal={setShowAddMemberModal}
        />
      )}
      <Card>
        <Box
          sx={{
            m: -1,
            p: 3,
          }}
        >
          <Typography
            variant="dashboardContentHeader"
            sx={{
              color: "#2D3E56",
              mb: "34px",
              display: "block",
            }}
          >
            Add New Portfolio
          </Typography>
          <Formik
            // enableReinitialize={true}
            initialValues={{
              portfolioName: "",
              estimatedBudget: "",
              estimatedWorkHours: "",
              portfolioSize: "",
              portfolioStatus: "",
              porfolioLocation: "",
              porfolioDescription: "",
              cPerson: taskAssignedTo,
              portfolioType: portFolioTypeData,
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              portfolioName: Yup.string()
                .max(255)
                .required("Portfolio Name is required"),
              estimatedWorkHours: Yup.number().typeError(
                "you must specify a number"
              ),
              portfolioSize: Yup.string().max(255),
              portfolioStatus: Yup.string().max(255),
              portfolioType: Yup.object().required(
                "Portfolio Type is required"
              ),
              cPerson: Yup.number()
                .typeError("Select a valid person")
                .nullable(),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const accessToken = window.localStorage.getItem("accessToken");

                const response = await axios.post(
                  `${process.env.REACT_APP_BACKEND_URL}/api/portfolio`,
                  {
                    name: values.portfolioName,
                    portfolio_type: portFolioTypeData.id,
                    contact_person: taskAssignedTo,
                    estimated_budget: getNumbers(values.estimatedBudget),
                    actual_cost: "0",
                    estimated_hours: values.estimatedWorkHours,
                    portfolio_size: values.portfolioSize,
                    status: values.portfolioStatus,
                    address: values.porfolioLocation,
                    description: values.porfolioDescription,
                    created_on:
                      moment(portfolioCreatedOn).format("YYYY/MM/DD/"),
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                    },
                  }
                );
                if (response.data.success) {
                  toast.success(response.data.data.message);
                  getPortfolioCount();
                  navigate("/dashboard/portfolios/view-all-portfolios");
                }
              } catch (err) {
                console.error(err);
                toast.error("Can't able to create portfolio");
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...props}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Portfolio Name "
                      required
                      error={Boolean(
                        touched.portfolioName && errors.portfolioName
                      )}
                      fullWidth
                      helperText={touched.portfolioName && errors.portfolioName}
                      margin="normal"
                      name="portfolioName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.portfolioName}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      error={Boolean(
                        touched.portfolioType && errors.portfolioType
                      )}
                      required
                    >
                      <InputLabel id="demo-simple-select-label">
                        Portfolio Type
                      </InputLabel>
                      <Select
                        fullWidth
                        label="Portfolio Type *"
                        margin="normal"
                        name="portfolioType"
                        value={portFolioTypeData}
                        onChange={(e) => {
                          handleProtfolioTypeChange(e);
                          setFieldValue("portfolioType", e.target.value);
                        }}
                        onBlur={handleBlur}
                        helperText={"help"}
                        renderValue={() => portFolioTypeData.type_name}
                      >
                        {allPortfolioTypes.map((portfolioType) => (
                          <MenuItem
                            key={portfolioType.id}
                            value={portfolioType}
                          >
                            <Radio
                              checked={
                                portfolioType.id === portFolioTypeData.id
                              }
                            />
                            <ListItemText primary={portfolioType.type_name} />
                          </MenuItem>
                        ))}
                        <Box sx={{ px: 3, py: 2 }}>
                          <TextField
                            fullWidth
                            onKeyDown={(e) => e.stopPropagation()}
                            placeholder="Add custom portfolio type"
                            margin="normal"
                            name="addNewPortfolioType"
                            onChange={(e) =>
                              setNewPortfolioName(e.target.value)
                            }
                            value={newPortfolioName}
                            variant="outlined"
                            sx={{ mt: 0 }}
                          ></TextField>
                          <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            fullWidth
                            sx={{ py: 2, mt: 1 }}
                            onClick={handlePortFolioType}
                          >
                            Save
                          </Button>
                        </Box>
                      </Select>
                      <FormHelperText>
                        {touched.portfolioType && errors.portfolioType}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Contact Person *"
                      error={Boolean(
                        touched.contactPerson && errors.contactPerson
                      )}
                      fullWidth
                      helperText={touched.contactPerson && errors.contactPerson}
                      margin="normal"
                      name="contactPerson"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.contactPerson}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      blurOnSelect={true}
                      options={dropdownMemberList}
                      PaperComponent={CustomAutocomplete}
                      onChange={handleUChange}
                      getOptionLabel={(option) => option.first_name}
                      renderOption={(props, option) => {
                        return (
                          <li
                            {...props}
                            key={`${option.first_name} ${option.last_name} ${option.id}`}
                          >
                            {`${option.first_name} ${option.last_name}`}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Contact Person"
                          name="cPerson"
                        />
                      )}
                    />
                  </Grid>

                  {/* <TextField
                    label="Estimated Budget "
                    error={Boolean(
                      touched.estimatedBudget && errors.estimatedBudget
                    )}
                    fullWidth
                    helperText={
                      touched.estimatedBudget && errors.estimatedBudget
                    }
                    margin="normal"
                    name="estimatedBudget"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.estimatedBudget}
                    variant="outlined"
                    sx={{ mt: 0 }}
                    InputProps={{
                      inputComponent: CurrencyFormatter,
                    }}
                    /> */}
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      fullWidth
                      className={`${classes.formControl} formikInputWrapper ${
                        blurActive ? "blured" : ""
                      }`}
                      error={errors.estimatedBudget && touched.estimatedBudget}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ backgroundColor: "#fff", px: 1 }}
                        shrink
                      >
                        Estimated Budget
                      </InputLabel>

                      <Box className="custom-mui-input-wrapper">
                        <Field
                          name="estimatedBudget"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={currencyMask}
                              id="estimatedBudget"
                              placeholder="$"
                              type="text"
                              onChange={handleChange}
                              value={values.estimatedBudget}
                              onBlur={(e) => {
                                setBlurActive(false);
                                handleBlur(e);
                              }}
                              onFocus={() => {
                                setBlurActive(true);
                              }}
                              className={
                                errors.estimatedBudget &&
                                touched.estimatedBudget
                                  ? "text-input error custom-mui-input"
                                  : "text-input custom-mui-input"
                              }
                            />
                          )}
                        />
                      </Box>
                      <FormHelperText>
                        {touched.estimatedBudget && errors.estimatedBudget}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Estimated Work Hours"
                      error={Boolean(
                        touched.estimatedWorkHours && errors.estimatedWorkHours
                      )}
                      fullWidth
                      helperText={
                        touched.estimatedWorkHours && errors.estimatedWorkHours
                      }
                      margin="normal"
                      name="estimatedWorkHours"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.estimatedWorkHours}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Portfolio Size"
                      error={Boolean(
                        touched.portfolioSize && errors.portfolioSize
                      )}
                      fullWidth
                      helperText={touched.portfolioSize && errors.portfolioSize}
                      margin="normal"
                      name="portfolioSize"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.portfolioSize}
                      variant="outlined"
                      sx={{ mt: 0 }}
                      select
                      SelectProps={{ native: true }}
                    >
                      <option value=""></option>
                      {portfolioSize.map((individualSize) => (
                        <option value={individualSize.value}>
                          {individualSize.title}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      error={!portfolioCreatedOn}
                    >
                      <InputLabel id="demo-simple-select-label" shrink>
                        Portfolio created on
                      </InputLabel>
                      <DatePicker
                        autoOk={true}
                        className="pt-dtpicker"
                        label={" Portfolio created on  "}
                        fullWidth
                        onChange={(date) => setPortfolioCreatedOn(date)}
                        value={portfolioCreatedOn}
                        name="portfolioCreatedon"
                        disablePast
                        error={false}
                        helperText=""
                        renderInput={(inputProps) => (
                          <TextField
                            className="pt-datepicker-ip"
                            fullWidth
                            variant="outlined"
                            {...inputProps}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Portfolio Status "
                      error={Boolean(
                        touched.portfolioStatus && errors.portfolioStatus
                      )}
                      fullWidth
                      helperText={
                        touched.portfolioStatus && errors.portfolioStatus
                      }
                      margin="normal"
                      name="portfolioStatus"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.portfolioStatus}
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    >
                      <option disabled value=""></option>
                      {portfolioStatus.map((status) => (
                        <option value={status.value}>{status.title}</option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label=" Portfolio Location "
                      error={Boolean(
                        touched.porfolioLocation && errors.porfolioLocation
                      )}
                      fullWidth
                      helperText={
                        touched.porfolioLocation && errors.porfolioLocation
                      }
                      margin="normal"
                      name="porfolioLocation"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.porfolioLocation}
                      variant="outlined"
                      sx={{ mt: 0 }}
                      rows={2}
                      multiline
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      color="textPrimary"
                      sx={{ mb: 1, fontSize: "14px", fontWeight: "700" }}
                      variant="subtitle2"
                    ></Typography>
                    <TextField
                      label=" Portfolio Description "
                      error={Boolean(
                        touched.porfolioDescription &&
                          errors.porfolioDescription
                      )}
                      fullWidth
                      helperText={
                        touched.porfolioDescription &&
                        errors.porfolioDescription
                      }
                      margin="normal"
                      name="porfolioDescription"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.porfolioDescription}
                      variant="outlined"
                      sx={{ mt: 0 }}
                      rows={2}
                      multiline
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box sx={{ display: "flex", mr: "auto", pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Card>
    </>
  );
};

export default CreatePortfolioForm;
