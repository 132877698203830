import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";
import phoneNumberMask from "../../../@const/phoneNumberMaskOptions";
import skills from "../../../@const/skills";
import { getDepartmentsForDropdown } from "../../../api/department/Department";
import { editPeople } from "../../../api/people/People";
import useAuth from "../../../hooks/useAuth";
import useMounted from "../../../hooks/useMounted";
import { getPhoneNumber } from "../../../utils/getPhoneNumber";
import { getAllDesignations } from "../../../api/admin/Designation";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const EditPeopleModal = (props) => {
  const {
    showEditPeopleModal,
    setShowEditPeopleModal,
    getAllPeopleUnderAdmin,
    member,
  } = props;

  const mounted = useMounted();
  const { user } = useAuth();
  const classes = useStyles();
  const [departments, setDepartments] = useState([]);
  const [blurActive, setBlurActive] = useState(false);
  const [designations, setDesignations] = useState([]);

  useEffect(async () => {
    if (mounted.current) {
      await getDesignations();
    }
    const res = await getDepartmentsForDropdown({ adminId: user.id });
    if (res?.data?.success) {
      setDepartments(res.data.data.departments);
    }
  }, []);

  const getDesignations = async () => {
    const res = await getAllDesignations();
    if (res.data) {
      setDesignations(res.data);
    }
  };

  return (
    <>
      <Dialog
        open={showEditPeopleModal}
        onClose={() => setShowEditPeopleModal(false)}
      >
        <Formik
          initialValues={{
            email: member.email,
            first_name: member.first_name,
            last_name: member.last_name,
            phone: member?.user_params?.phone || "",
            department: member?.user_params?.department,
            designation: member?.user_params?.designation,
            hourly_rate: member?.user_params?.hourly_rate,
            skills:
              member?.user_params?.get_user_skills?.skill
                .replace(/[\[\]']+/g, "")
                .split(",") || [],
            experience: member?.user_params?.experience,
            dailyWorkHours: member?.user_params?.daily_work_hours,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            first_name: Yup.string()
              .max(255)
              .required("First Name is required"),
            last_name: Yup.string().max(255).required("Last Name is required"),
            department: Yup.string().max(255).nullable(),
            hourly_rate: Yup.number()
              .max(9999)
              .required("Hourly Rate is required"),
            skills: Yup.array().nullable(),
            experience: Yup.string().max(255).nullable(),
            dailyWorkHours: Yup.number()
              .max(12)
              .typeError("Hours per day must be a number")
              .required("Hours per day is required"),
            designation: Yup.string()
              .max(255)
              .required("Job Title is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const memberData = {
                admin_id: user.id,
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                phone: getPhoneNumber(values.phone),
                department: values.department,
                role: "3",
                designation: values.designation,
                hourly_rate: values.hourly_rate,
                skills:
                  values.skills.length > 0
                    ? `[${values.skills.toString()}]`
                    : "",
                experience: values.experience,
                daily_work_hours: values.dailyWorkHours,
              };

              const res = await editPeople(memberData, member.id);

              if (res.data.success) {
                toast.success("Member data updated");
                setShowEditPeopleModal(false);
                getAllPeopleUnderAdmin();
              }

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              <DialogTitle>Edit People</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="First Name"
                      required
                      error={Boolean(touched.first_name && errors.first_name)}
                      fullWidth
                      helperText={touched.first_name && errors.first_name}
                      margin="normal"
                      name="first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Last Name"
                      required
                      error={Boolean(touched.last_name && errors.last_name)}
                      fullWidth
                      helperText={touched.last_name && errors.last_name}
                      margin="normal"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      required
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <TextField
                      label="Phone Number"
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      margin="normal"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    /> */}
                    <FormControl
                      fullWidth
                      className={`${classes.formControl} formikInputWrapper ${
                        blurActive ? "blured" : ""
                      }`}
                      error={errors.phone && touched.phone}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ backgroundColor: "#fff", px: 1 }}
                        shrink
                      >
                        Phone Number
                      </InputLabel>

                      <Box className="custom-mui-input-wrapper">
                        <Field
                          name="phone"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              id="phone"
                              placeholder="(XXX) XXX-XXXX"
                              type="text"
                              onChange={handleChange}
                              value={values.phone}
                              onBlur={(e) => {
                                setBlurActive(false);
                                handleBlur(e);
                              }}
                              onFocus={() => {
                                setBlurActive(true);
                              }}
                              className={
                                errors.phone && touched.phone
                                  ? "text-input error custom-mui-input"
                                  : "text-input custom-mui-input"
                              }
                            />
                          )}
                        />
                      </Box>
                      <FormHelperText>
                        {touched.phone && errors.phone}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                          Department
                        </InputLabel>
                        <Select
                          labelId="select-status"
                          label="department"
                          name="department"
                          value={values.department}
                          error={Boolean(
                            touched.department && errors.department
                          )}
                          fullWidth
                          helperText={touched.department && errors.department}
                          placeholder="department"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <MenuItem value="">none</MenuItem>
                          {departments.length > 0 &&
                            departments.map((singleDepartment) => (
                              <MenuItem
                                key={singleDepartment.id}
                                value={singleDepartment.id}
                              >
                                {singleDepartment.department}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                          Designation
                        </InputLabel>
                        <Select
                          labelId="select-status"
                          label="designation *"
                          name="designation"
                          value={values.designation}
                          error={Boolean(
                            touched.designation && errors.designation
                          )}
                          fullWidth
                          helperText={touched.designation && errors.designation}
                          placeholder="designation"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <MenuItem value="">none</MenuItem>
                          {designations.length > 0 &&
                            designations.map((des) => (
                              <MenuItem key={des.id} value={des.name}>
                                {des.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label=" Hourly Rate "
                      required
                      error={Boolean(touched.hourly_rate && errors.hourly_rate)}
                      fullWidth
                      helperText={touched.hourly_rate && errors.hourly_rate}
                      margin="normal"
                      name="hourly_rate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.hourly_rate}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Hours Per Day"
                      required
                      error={Boolean(
                        touched.dailyWorkHours && errors.dailyWorkHours
                      )}
                      fullWidth
                      helperText={
                        touched.dailyWorkHours && errors.dailyWorkHours
                      }
                      margin="normal"
                      name="dailyWorkHours"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dailyWorkHours}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                          Skills
                        </InputLabel>
                        <Select
                          labelId="select-status"
                          label="skills *"
                          name="skills"
                          value={values.skills}
                          error={Boolean(touched.skills && errors.skills)}
                          fullWidth
                          helperText={touched.skills && errors.skills}
                          placeholder="skills"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className="multiselect-dropdown"
                          multiple
                        >
                          <MenuItem value="">none</MenuItem>
                          {skills.length > 0 &&
                            skills.map((skill) => (
                              <MenuItem
                                className="multiselect-dropdown-menu"
                                key={skill.value}
                                value={skill.value}
                              >
                                {skill.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Experience"
                      error={Boolean(touched.experience && errors.experience)}
                      fullWidth
                      helperText={touched.experience && errors.experience}
                      margin="normal"
                      name="experience"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.experience}
                      variant="outlined"
                      sx={{ mt: 0 }}
                      select
                      SelectProps={{ native: true }}
                    >
                      <option value={1}>0-1 Years</option>
                      <option value={2}>1-2 years</option>
                      <option value={3}>5-10 years</option>
                    </TextField>
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowEditPeopleModal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default EditPeopleModal;
