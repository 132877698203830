import AdminIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DepartmentIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/GroupsOutlined";
import BudgetIcon from "@mui/icons-material/MonetizationOnOutlined";
import Portfolio from "../icons/sidebar/Portfolio";
import Project from "../icons/sidebar/Project";
import CalendarIcon from "../icons/Calendar";
const superAdminRoutes = [
  {
    items: [
      {
        title: "Portfolios",
        path: "/dashboard/portfolios/view-all-portfolios",
        icon: <Portfolio className="nav-left-icon" fontSize="small" />,
        children: [],
      },
      {
        title: "Projects",
        path: "/dashboard/projects/view-all-projects",
        icon: <Project className="nav-left-icon" fontSize="small" />,
        children: [
          { title: "View all", path: "/dashboard/projects/view-all-projects" },
          {
            title: "Project Intake",
            path: "/dashboard/score",
          },
          {
            title: "Workflow",
            path: "/dashboard/admin-setting/stages-setting",
          },
          {
            title: "Project Template",
            path: "/dashboard/admin-setting/project-template",
          },
        ],
      },
      {
        title: "Budget",
        path: "/dashboard/budget",
        icon: <BudgetIcon className="nav-left-icon" fontSize="small" />,
        children: [],
      },
      {
        title: "People Ops",
        path: "/dashboard/peoples",
        icon: <PeopleIcon className="nav-left-icon" fontSize="small" />,
        children: [
          {
            title: "View All",
            path: "/dashboard/peoples/view-all",
          },
          {
            title: "Utilization",
            path: "/dashboard/peoples/utilization",
          },
          {
            title: "My Task Portal",
            path: "/dashboard/projects/my-task-portal",
          },
          {
            title: "Time Entry",
            path: "/dashboard/time/add-new",
          },
          {
            title: "Approval",
            path: "/dashboard/time/approve",
          },
          // {
          //   title: "Calendar",
          //   path: "/dashboard/calendar",
          // },
        ],
      },
      {
        title: "Calendar",
        path: "/dashboard/calendar",
        icon: <CalendarIcon className="nav-left-icon" fontSize="small" />,
        children: [
          {
            title: "Calendar",
            path: "/dashboard/calendar",
          },
          {
            title: "Scheduling",
            path: "/dashboard/scheduling/members-available",
          },
        ],
      },
      // {
      //   title: "Calendar",
      //   path: "/dashboard/calendar",
      //   icon: <CalendarIcon className="nav-left-icon" fontSize="small" />,
      //   children: [],
      // },
      {
        title: "Departments",
        path: "/dashboard/departments/view-all",
        icon: <DepartmentIcon className="nav-left-icon" fontSize="small" />,
        children: [],
      },

      {
        title: "Reports",
        path: "/dashboard/reports",
        icon: <BudgetIcon className="nav-left-icon" fontSize="small" />,
        children: [
          {
            title: "Project Report",
            path: "/dashboard/reports?p=project-status",
          },
          {
            title: "More Reports",
            path: "/dashboard/reports/more",
          },
          {
            title: "Custom Report",
            path: "/dashboard/reports/custom?p=project",
          },
        ],
      },
      // {
      //   title: "Time",
      //   path: "/dashboard/time",
      //   icon: <TimeIcon className="nav-left-icon" fontSize="small" />,
      //   children: [
      //     {
      //       title: "Time Entry",
      //       path: "/dashboard/time/add-new",
      //     },
      //     {
      //       title: "Approval",
      //       path: "/dashboard/time/approve",
      //     },
      //   ],
      // },
      {
        title: "Manage Template",
        path: "/dashboard/admin-setting/manage-excel-template",
        icon: <CalendarIcon className="nav-left-icon" fontSize="small" />,
        children: [],
      },
      {
        title: "Admin Settings",
        path: "/dashboard/admin-setting",
        icon: <AdminIcon className="nav-left-icon" fontSize="small" />,
        children: [
          {
            title: "Users",
            path: "/dashboard/admin-setting/view-all-credentials",
          },
          {
            title: "New Registrations",
            path: "/dashboard/admin-setting/new-registrations",
          },
          {
            title: "Pending Requests",
            path: "/dashboard/admin-setting/requested-user",
          },
          {
            title: "Company Access Level",
            path: "/dashboard/admin-setting/company-access-level",
          },
          {
            title: "Manage Designation",
            path: "/dashboard/admin-setting/manage-designation",
          },
          {
            title: "Manage Templates",
            path: "/dashboard/admin-setting/manage-excel-template",
          },
          // {
          //   title: "Workflow",
          //   path: "/dashboard/admin-setting/stages-setting",
          // },
          // {
          //   title: "Project Template",
          //   path: "/dashboard/admin-setting/project-template",
          // },
          {
            title: "Archive",
            path: "/dashboard/admin-setting/archive",
          },
          // {
          //   title: "Intake Form",
          //   path: "/dashboard/room-intake-form",
          // },
        ],
      },
    ],
  },
];

export default superAdminRoutes;
