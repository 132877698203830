import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
  TextField,
  Card,
  Breadcrumbs,
  Container,
  Link,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import DatePicker from "@mui/lab/DatePicker";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import { makeStyles } from "@mui/styles";
import AddTime from "../../components/dashboard/time/AddTime";
import { getDepartmentsForDropdown } from "../../api/department/Department";
import useAuth from "../../hooks/useAuth";
import { getUsersUnderAdmin } from "../../api/project/Project";
import SingleProjectHour from "../../components/dashboard/time/SingleProjectHour";
import moment from "moment";
import { getTimesheetRecord } from "../../api/time/Time";
import WeekInfo from "../../components/dashboard/time/WeekInfo";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const getMondayInTheWeekFromDate = (date) => {
  console.log(date);
  const dt = moment(date);
  const res = moment(dt).isoWeekday(1).format("YYYY-MM-DD");
  return res;
};

const getClosestNextSunday = (date) => {
  const dt = moment(date);
  const res = moment(dt).isoWeekday(7).format("YYYY-MM-DD");
  return res;
};

const AddNewTiming = () => {
  const { settings } = useSettings();
  const [filterStartDate, setFilterStartDate] = useState(
    moment(new Date()).subtract(7, "days")
  );
  const [filterEndDate, setFilterEnddate] = useState(
    moment(new Date()).add(7, "days")
  );

  const [showAddTimeModal, setshowAddTimeModal] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [timeLogData, setTimeLogData] = useState([]);
  const { user } = useAuth();
  const classes = useStyles();

  useEffect(() => {
    getDepartmentData();

    if (user.role !== 1 && user.role !== 2) {
      setSelectedUser(user);
    }
  }, [user]);

  useEffect(() => {
    const getMemberList = async () => {
      const dept = selectedDepartment === "" ? 0 : selectedDepartment;
      const res = await getUsersUnderAdmin(0, dept);
      if (res?.data?.success) {
        setMemberList(res.data.data.peopleDropdown);
      } else {
        console.log("No Members");
      }
    };

    getMemberList();
  }, [selectedDepartment]);
  useEffect(() => {
    if (selectedUser?.id) {
      getUserTimeLogHistory();
    }
  }, [selectedUser, filterStartDate, filterEndDate]);

  const getDepartmentData = async () => {
    const res = await getDepartmentsForDropdown();
    if (res?.data?.success) {
      setDepartments(res.data.data.departments);
    }
  };

  const handleUChange = (e, val) => {
    e.preventDefault();
    setSelectedUser(val);
  };

  const getUserTimeLogHistory = async () => {
    const res = await getTimesheetRecord({
      userId: selectedUser.id,
      startDate: getMondayInTheWeekFromDate(filterStartDate),
      endDate: getClosestNextSunday(filterEndDate),
    });
    if (res?.data?.success) {
      const timelogtemp = JSON.parse(res.data.data.value);
      setTimeLogData(timelogtemp.timeLogGroupData);
    }
  };

  const generateWeekDates = (weekNumber, year) => {
    const weekArr = [];
    const weekStart = moment()
      .isoWeek(weekNumber)
      .year(year)
      .startOf("isoWeek");
    for (var i = 0; i <= 6; i++) {
      const newDate = moment(weekStart).add(i, "days").format("YYYY-MM-DD");
      weekArr.push(newDate);
    }
    return weekArr;
  };

  const getTotalWeekHours = useCallback(
    () =>
      timeLogData
        .filter((item) => item?.dayWiseSubTotal?.length)
        .flatMap((item) => item.dayWiseSubTotal)
        .filter((item) =>
          moment(item.date).isBetween(filterStartDate, filterEndDate)
        )
        .reduce((total, item) => total + item.hrs, 0),
    [timeLogData, filterStartDate, filterEndDate]
  );
  return (
    <>
      <Helmet>
        <title>Dashboard: Member Timing</title>
      </Helmet>
      <AddTime
        showAddTimeModal={showAddTimeModal}
        setshowAddTimeModal={setshowAddTimeModal}
        selectedUser={selectedUser}
        getUserTimeLogHistory={getUserTimeLogHistory}
      />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Time Entry
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Time Entry
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  disabled={selectedUser === ""}
                  onClick={() => setshowAddTimeModal(true)}
                >
                  ENTER TIME
                </Button>
              </Box>
            </Grid>
          </Grid>
          {(user?.role === 1 || user?.role === 2) && (
            <Card sx={{ mt: 3 }}>
              <Box
                sx={{
                  m: -1,
                  p: 3,
                }}
              >
                <Typography
                  variant="dashboardContentHeader"
                  sx={{
                    color: "#2D3E56",
                    mb: "18px",
                    display: "block",
                  }}
                >
                  Member Timesheet
                </Typography>
                <Grid container spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <Box sx={{ width: "100%" }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          id="department-select"
                          shrink
                          sx={{ color: "#bbb", px: 1, background: "#fff" }}
                        >
                          Department{" "}
                        </InputLabel>
                        <Select
                          labelId="department-select"
                          label="Department"
                          name=""
                          value={selectedDepartment}
                          onChange={(e) =>
                            setSelectedDepartment(e.target.value)
                          }
                          fullWidth
                          placeholder="department"
                          displayEmpty
                        >
                          <MenuItem value="">All</MenuItem>
                          {departments.length > 0 &&
                            departments.map((singleDepartment) => (
                              <MenuItem
                                key={singleDepartment.id}
                                value={singleDepartment.id}
                              >
                                {singleDepartment.department}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      blurOnSelect={true}
                      options={memberList}
                      onChange={handleUChange}
                      getOptionLabel={(option) =>
                        ` ${option.first_name} ${option.last_name} `
                      }
                      renderOption={(props, option) => {
                        return (
                          <li
                            {...props}
                            key={`${option.first_name} ${option.last_name} ${option.id}`}
                          >
                            {`${option.first_name} ${option.last_name}`}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search User"
                          name="cPerson"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    m: 1,
                    maxWidth: "100%",
                    width: 240,
                  }}
                ></Box>
              </Box>
            </Card>
          )}
          {selectedUser === "" ? (
            <Card sx={{ mt: 2, p: 3 }}>
              <Typography align="center">
                Please select a user to view timing
              </Typography>
            </Card>
          ) : (
            <Card sx={{ mt: 2, pt: 3 }}>
              <Grid sx={{ p: 2, pb: 3 }} container spacing={3}>
                <Grid item sm={12} md={3}>
                  <Box
                    sx={{
                      display: "flex",
                      ".MuiFormHelperText-root": {
                        display: "none",
                      },
                    }}
                  >
                    <DatePicker
                      label="Filter List Start"
                      autoOk={true}
                      className="pt-dtpicker"
                      fullWidth
                      onChange={(date) => setFilterStartDate(date)}
                      helperText={null}
                      value={filterStartDate}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name="filterStartDate"
                      renderInput={(inputProps) => (
                        <TextField
                          className="pt-datepicker-ip"
                          fullWidth
                          helperText={null}
                          variant="outlined"
                          {...inputProps}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} md={3}>
                  <Box
                    sx={{
                      display: "flex",
                      ".MuiFormHelperText-root": {
                        display: "none",
                      },
                    }}
                  >
                    <DatePicker
                      label="Filter List End"
                      autoOk={true}
                      className="pt-dtpicker"
                      fullWidth
                      onChange={(date) => setFilterEnddate(date)}
                      helperText={null}
                      value={filterEndDate}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name="filterEndDate"
                      renderInput={(inputProps) => (
                        <TextField
                          fullWidth
                          helperText={null}
                          variant="outlined"
                          {...inputProps}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} md={3}>
                  <TextField
                    placeholder="Total Hours"
                    value={`Total Hours : ${getTotalWeekHours()}`}
                  />
                </Grid>
              </Grid>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ACTIONS</TableCell>
                    <TableCell>DESCRIPTION</TableCell>
                    <TableCell>WEEK</TableCell>
                    <TableCell>MON</TableCell>
                    <TableCell>TUE</TableCell>
                    <TableCell>WED</TableCell>
                    <TableCell>THU</TableCell>
                    <TableCell>FRI</TableCell>
                    <TableCell>SAT</TableCell>
                    <TableCell>SUN</TableCell>
                    <TableCell>TOTAL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timeLogData?.map((singleDayTimeLog) => (
                    <>
                      {singleDayTimeLog.initiated ? (
                        <>
                          <WeekInfo
                            startDate={filterStartDate}
                            endDate={filterEndDate}
                            singleDayTimeLog={singleDayTimeLog}
                            weekDates={singleDayTimeLog?.tasks[0]?.weeklyReport}
                            hasRecord={true}
                            getUserTimeLogHistory={getUserTimeLogHistory}
                            week={singleDayTimeLog.week}
                            selectedUser={selectedUser}
                            year={singleDayTimeLog.year}
                          />
                          {singleDayTimeLog?.tasks?.map((singleTask) => (
                            <SingleProjectHour
                              getUserTimeLogHistory={getUserTimeLogHistory}
                              week={singleDayTimeLog.week}
                              selectedUser={selectedUser}
                              year={singleDayTimeLog.year}
                              singleTask={singleTask}
                            />
                          ))}
                          <TableRow
                            sx={{
                              td: {
                                borderBottom: "2px solid #C0CBDE",
                              },
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>SUB TOTAL</TableCell>
                            {singleDayTimeLog?.dayWiseSubTotal.map(
                              (singleDayInAWeek) => (
                                <TableCell>{singleDayInAWeek.hrs}</TableCell>
                              )
                            )}
                            <TableCell sx={{ fontWeight: "600" }}>
                              {singleDayTimeLog.totalWeekHours}
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <>
                          <WeekInfo
                            hasRecord={false}
                            singleDayTimeLog={singleDayTimeLog}
                            weekDates={generateWeekDates(
                              singleDayTimeLog.week,
                              singleDayTimeLog.year
                            )}
                            getUserTimeLogHistory={getUserTimeLogHistory}
                            week={singleDayTimeLog.week}
                            selectedUser={selectedUser}
                            year={singleDayTimeLog.year}
                          />
                        </>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </Card>
          )}
        </Container>
      </Box>
    </>
  );
};

export default AddNewTiming;
