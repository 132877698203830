import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import { getSavedTemplates } from "../../api/project/Project";
import ViewAllProjectTemplates from "../../components/dashboard/admin/ViewAllProjectTemplates";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";

const ProjectTemplate = () => {
  const { settings } = useSettings();
  const [allTemplates, setAllTemplates] = useState([]);
  const [nameSearchValue, setNameSearchValue] = useState("");

  const searchHandler = (e) => {
    e.preventDefault();
    setNameSearchValue(e.target.value);
  };

  useEffect(() => {
    getAllTemplates();
  }, [nameSearchValue]);

  const getAllTemplates = async () => {
    const res = await getSavedTemplates(nameSearchValue);
    if (res?.data?.data?.items.length) {
      setAllTemplates(res.data.data.items);
    } else {
      setAllTemplates([]);
    }
  };
  return (
    <>
      <Helmet>
        <title>Dashboard: View All Credentials |</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Admin Settings
                </Typography>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Project templates
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Project Templates
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <ViewAllProjectTemplates
              data={allTemplates}
              getAllTemplates={getAllTemplates}
              searchHandler={searchHandler}
              nameSearchValue={nameSearchValue}
            />
          </Box>
          <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
            <Typography variant="h6" color="secondary">
              {allTemplates.length ? "" : "No Records Available"}
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProjectTemplate;
