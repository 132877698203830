import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";
import moment from "moment";
import { getPortfolioDashboardGanttChart } from "../../../api/portfolio/Portfolio";

highchartsGantt(Highcharts);

const DashboardGanttChart = ({ id, authToken = null }) => {
  const [ganttData, setGanttData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    series: [{ data: [] }],
  });
  const chartRef = useRef(null);
  useEffect(async () => {
    const res = await getPortfolioDashboardGanttChart(id, authToken);
    if (res?.data?.data?.items.length > 0) {
      const ganttData = res?.data?.data?.items;

      const ganttDataWithUnixDate = ganttData.map((obj) => {
        if (obj.x && obj.x2) {
          const x1date = moment(obj.x, "YYYY-MM-DD")
            .format("YYYY-MM-DD")
            .split("-");
          const nextEndDay = moment(obj.x2, "YYYY-MM-DD")
            .add(1, "days")
            .format("YYYY-MM-DD");
          const x2date = nextEndDay.split("-");

          const x = Date.UTC(x1date[0], x1date[1], x1date[2]);
          const x2 = Date.UTC(x2date[0], x2date[1], x2date[2]);
          console.log(x2);

          return { ...obj, x: x, x2: x2 };
        }
        return obj;
      });
      setChartOptions({
        chart: {
          type: "xrange",
        },

        rangeSelector: {
          enabled: true,
          buttons: [
            {
              type: "month",
              count: 12,
              text: "12m",
            },
            {
              type: "all",
              text: "All",
            },
          ],
        },
        yAxis: {
          labels: {
            formatter: function () {
              // Get the key of the the data that is the id of the project from the ganttDataWithUnixDate
              const projectId = ganttDataWithUnixDate.find(
                (item) => item.name === this.value
              )?.id;
              console.log(projectId);
              if (projectId) {
                return `<a href="/dashboard/projects/view-and-edit-project/${projectId}?p=dashboard">${this.value}</a>`;
              }
              return this.value;
            },
          },
        },
        scrollbar: { enabled: true, liveRedraw: false },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
            connectors: {
              lineWidth: 1,
              type: "fastAvoid",
              startMarker: {
                enabled: true,
                symbol: "arrow-filled",
                align: "left",
              },
            },
          },
        },

        tooltip: {
          formatter: function () {
            const dt1 = new Date(this.x);
            const dt2 = new Date(this.x2);
            dt2.setDate(dt2.getDate() - 1);

            return `<b>Name: ${this.key}</b><br/>Start Date: <b>${
              dt1.getMonth() === 0 ? 12 : dt1.getMonth()
            }-${dt1.getDate()}-${dt1.getFullYear()}</b>
            <br/>End Date: <b>${
              dt2.getMonth() === 0 ? 12 : dt2.getMonth()
            }-${dt2.getDate()}-${dt2.getFullYear()}</b><br/>
            `;
          },
        },
        series: [{ data: ganttDataWithUnixDate }],
      });
      setGanttData(ganttDataWithUnixDate);
    }
  }, []);
  return (
    <div>
      {ganttData?.length > 0 ? (
        <HighchartsReact
          constructorType="ganttChart"
          highcharts={Highcharts}
          options={chartOptions}
          ref={chartRef}
        />
      ) : (
        <Typography align="center" sx={{ p: 4 }} variant="h6" color="secondary">
          No projects available
        </Typography>
      )}
    </div>
  );
};

export default DashboardGanttChart;
