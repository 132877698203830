import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import { getDepartmentsForDropdown } from "../../api/department/Department";
import { getUsersUnderAdmin } from "../../api/project/Project";
import {
  checkForQuickbooksSubscription,
  getTimesheetRecord,
} from "../../api/time/Time";
import AddTime from "../../components/dashboard/time/AddTime";
import SinglePendingProjectHour from "../../components/dashboard/time/SinglePendingProjectHour";
import useAuth from "../../hooks/useAuth";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const URL = window.location.origin;
const quickbooksUrl = URL.includes("staging-app")
  ? "https://staging-quickbooks.projectric.com/"
  : "https://quickbooks.projectric.com/";

const PendingTimeRequests = () => {
  const { settings } = useSettings();
  const [filterStartDate, setFilterStartDate] = useState(
    moment(new Date()).subtract(30, "days")
  );
  const [filterEndDate, setFilterEnddate] = useState(
    moment(new Date()).add(30, "days")
  );

  const [showAddTimeModal, setshowAddTimeModal] = useState(false);
  const [isSubscribedToQuickbooks, setIsSubscribedToQuickbooks] =
    useState(false);
  const [timeFilter, setTimeFilter] = useState(1);
  const [departments, setDepartments] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [timeLogData, setTimeLogData] = useState([]);
  const { user } = useAuth();
  const classes = useStyles();

  useEffect(() => {
    getDepartmentData();
    checkSubscription();
  }, []);

  useEffect(() => {
    getMemberList();
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedUser?.id) {
      getUserTimeLogHistory();
    }
  }, [selectedUser, filterStartDate, filterEndDate]);

  const getDepartmentData = async () => {
    const res = await getDepartmentsForDropdown({ adminId: user.id });
    if (res?.data?.success) {
      setDepartments(res.data.data.departments);
    }
  };

  const checkSubscription = async () => {
    const res = await checkForQuickbooksSubscription();
    if (res?.data?.success) {
      setIsSubscribedToQuickbooks(res.data.data.value);
    }
  };

  const getMemberList = async () => {
    const dept = selectedDepartment === "" ? 0 : selectedDepartment;
    const res = await getUsersUnderAdmin(0, dept);
    if (res.data.success) {
      setMemberList(res.data.data.peopleDropdown);
    } else {
      console.log("No Members");
    }
  };

  const handleUChange = (e, val) => {
    e.preventDefault();
    setSelectedUser(val);
  };

  const getUserTimeLogHistory = async () => {
    const res = await getTimesheetRecord({
      userId: selectedUser.id,
      startDate: moment(filterStartDate).startOf("week").format("YYYY-MM-DD"),
      endDate: moment(filterEndDate).endOf("week").format("YYYY-MM-DD"),
    });
    if (res.data.success) {
      const timelogtemp = JSON.parse(res.data.data.value);
      setTimeLogData(timelogtemp.timeLogGroupData);
    }
  };

  const openQuickbooks = () => {
    let win;
    let parameters = "location=1,width=800,height=650";
    let screen = window.screen;
    parameters +=
      ",left=" + (screen.width - 800) / 2 + ",top=" + (screen.height - 650) / 2;

    // Launch Popup
    win = window.open(
      `${quickbooksUrl}?company_id=4`,
      "Quickbooks",
      parameters
    );
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Member Timing</title>
      </Helmet>
      <AddTime
        showAddTimeModal={showAddTimeModal}
        setshowAddTimeModal={setshowAddTimeModal}
        selectedUser={selectedUser}
      />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Approval
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Approve Time Entry
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                {isSubscribedToQuickbooks && (
                  <Button
                    sx={{
                      m: 1,
                      backgroundColor: "#3f51b5",
                      color: "#fff",
                      "&:hover": { backgroundColor: "#3461b5" },
                    }}
                    variant="contained"
                    onClick={() => openQuickbooks(true)}
                  >
                    Export to Quickbooks
                  </Button>
                )}

                <Button
                  color="primary"
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  disabled={selectedUser === ""}
                  onClick={() => setshowAddTimeModal(true)}
                >
                  ENTER TIME
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Card sx={{ mt: 3 }}>
            <Box
              sx={{
                m: -1,
                p: 3,
              }}
            >
              <Typography
                variant="dashboardContentHeader"
                sx={{
                  color: "#2D3E56",
                  mb: "18px",
                  display: "block",
                }}
              >
                Member Timesheet
              </Typography>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Box sx={{ width: "100%" }}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                        Department{" "}
                      </InputLabel>
                      <Select
                        labelId="select-status"
                        label="Department"
                        name=""
                        value={selectedDepartment}
                        onChange={(e) => setSelectedDepartment(e.target.value)}
                        fullWidth
                        placeholder="department"
                      >
                        <MenuItem value="">none</MenuItem>
                        {departments.length > 0 &&
                          departments.map((singleDepartment) => (
                            <MenuItem
                              key={singleDepartment.id}
                              value={singleDepartment.id}
                            >
                              {singleDepartment.department}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    blurOnSelect={true}
                    options={memberList}
                    onChange={handleUChange}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          key={`${option.first_name} ${option.last_name} ${option.id}`}
                        >
                          {`${option.first_name} ${option.last_name}`}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search User"
                        name="cPerson"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  m: 1,
                  maxWidth: "100%",
                  width: 240,
                }}
              ></Box>
            </Box>
          </Card>
          {selectedUser === "" ? (
            <Card sx={{ mt: 2, p: 3 }}>
              <Typography align="center">
                Please select a user to approve time entries
              </Typography>
            </Card>
          ) : (
            <Card sx={{ mt: 2, pt: 3 }}>
              <Grid sx={{ p: 2, pb: 3 }} container spacing={3}>
                <Grid item sm={6} md={3}>
                  <Box
                    sx={{
                      display: "flex",
                      ".MuiFormHelperText-root": {
                        display: "none",
                      },
                    }}
                  >
                    <DatePicker
                      label="Filter List Start"
                      autoOk={true}
                      className="pt-dtpicker"
                      fullWidth
                      onChange={(date) => setFilterStartDate(date)}
                      helperText={null}
                      value={filterStartDate}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name="filterStartDate"
                      renderInput={(inputProps) => (
                        <TextField
                          fullWidth
                          helperText={null}
                          variant="outlined"
                          {...inputProps}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} md={3}>
                  <Box
                    sx={{
                      display: "flex",
                      ".MuiFormHelperText-root": {
                        display: "none",
                      },
                    }}
                  >
                    <DatePicker
                      label="Filter List End"
                      autoOk={true}
                      className="pt-dtpicker"
                      fullWidth
                      onChange={(date) => setFilterEnddate(date)}
                      helperText={null}
                      value={filterEndDate}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name="filterEndDate"
                      renderInput={(inputProps) => (
                        <TextField
                          fullWidth
                          helperText={null}
                          variant="outlined"
                          {...inputProps}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box sx={{ width: "100%" }}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                        Filter{" "}
                      </InputLabel>
                      <Select
                        labelId="select-status"
                        label="Filter"
                        name=""
                        value={timeFilter}
                        onChange={(e) => setTimeFilter(e.target.value)}
                        fullWidth
                        placeholder="Filter"
                      >
                        <MenuItem value={1}>All</MenuItem>
                        <MenuItem value={2}>Approved</MenuItem>
                        <MenuItem value={3}>Requested</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ACTIONS</TableCell>
                    <TableCell>DESCRIPTION</TableCell>
                    <TableCell>WEEK</TableCell>
                    <TableCell>MON</TableCell>
                    <TableCell>TUE</TableCell>
                    <TableCell>WED</TableCell>
                    <TableCell>THU</TableCell>
                    <TableCell>FRI</TableCell>
                    <TableCell>SAT</TableCell>
                    <TableCell>SUN</TableCell>
                    <TableCell>TOTAL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timeLogData.map((singleDayTimeLog) => (
                    <>
                      {singleDayTimeLog.initiated ? (
                        <>
                          <TableRow
                            sx={{
                              td: {
                                borderBottom: "1px solid #ECF1F9",
                              },
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              {singleDayTimeLog.year}-WK({singleDayTimeLog.week}
                              )
                            </TableCell>
                            {singleDayTimeLog?.tasks[0]?.weeklyReport.map(
                              (singleDayInAWeek) => (
                                <TableCell>
                                  {singleDayInAWeek.date.split("-")[2]}
                                </TableCell>
                              )
                            )}
                            <TableCell></TableCell>
                          </TableRow>

                          {singleDayTimeLog?.tasks?.map((singleTask) => {
                            if (timeFilter !== 1) {
                              if (singleTask.isApproved && timeFilter === 2) {
                                return (
                                  <SinglePendingProjectHour
                                    getUserTimeLogHistory={
                                      getUserTimeLogHistory
                                    }
                                    week={singleDayTimeLog.week}
                                    selectedUser={selectedUser}
                                    year={singleDayTimeLog.year}
                                    singleTask={singleTask}
                                  />
                                );
                              } else if (
                                !singleTask.isApproved &&
                                timeFilter === 3
                              ) {
                                return (
                                  <SinglePendingProjectHour
                                    getUserTimeLogHistory={
                                      getUserTimeLogHistory
                                    }
                                    week={singleDayTimeLog.week}
                                    selectedUser={selectedUser}
                                    year={singleDayTimeLog.year}
                                    singleTask={singleTask}
                                  />
                                );
                              }
                            } else if (timeFilter === 1) {
                              return (
                                <SinglePendingProjectHour
                                  getUserTimeLogHistory={getUserTimeLogHistory}
                                  week={singleDayTimeLog.week}
                                  selectedUser={selectedUser}
                                  year={singleDayTimeLog.year}
                                  singleTask={singleTask}
                                />
                              );
                            }
                            return <></>;
                          })}
                          <TableRow
                            sx={{
                              td: {
                                borderBottom: "2px solid #C0CBDE",
                              },
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>SUB TOTAL</TableCell>
                            {singleDayTimeLog?.dayWiseSubTotal.map(
                              (singleDayInAWeek) => (
                                <TableCell>{singleDayInAWeek.hrs}</TableCell>
                              )
                            )}
                            <TableCell sx={{ fontWeight: "600" }}>
                              {singleDayTimeLog.totalWeekHours}
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <>
                          {/* <TableRow
                            sx={{
                              td: {
                                borderBottom: "1px solid #ECF1F9",
                              },
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              {singleDayTimeLog.year}-WK({singleDayTimeLog.week}
                              )
                            </TableCell>
                            {generateWeekDates(
                              singleDayTimeLog.week,
                              singleDayTimeLog.year
                            ).map((singleDayInAWeek) => (
                              <TableCell>
                                {singleDayInAWeek.split("-")[2]}
                              </TableCell>
                            ))}
                            <TableCell></TableCell>
                          </TableRow>

                          <SinglePendingProjectHour
                            getUserTimeLogHistory={getUserTimeLogHistory}
                            week={singleDayTimeLog.week}
                            selectedUser={selectedUser}
                            year={singleDayTimeLog.year}
                            singleTask={datas[0].tasks[0]}
                          />
                          <TableRow
                            sx={{
                              td: {
                                borderBottom: "2px solid #C0CBDE",
                              },
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>SUB TOTAL</TableCell>
                            {[0, 1, 2, 3, 4, 5, 6].map((singleDayInAWeek) => (
                              <TableCell>0</TableCell>
                            ))}
                            <TableCell sx={{ fontWeight: "600" }}>0</TableCell>
                          </TableRow> */}
                        </>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </Card>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PendingTimeRequests;
