import React, { useState, useEffect } from "react";
import {
  FormHelperText,
  TextField,
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import useMounted from "../../../hooks/useMounted";
import DatePicker from "@mui/lab/DatePicker";
import toast from "react-hot-toast";
import {
  addNewMilestone,
  getMilestoneTypes,
} from "../../../api/project/Project";
import { makeStyles } from "@mui/styles";
import { getStage } from "../../../api/admin/Stage";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const AddNewMilestoneModal = ({
  selectedProject,
  showAddMilestoneModal,
  setShowAddMilestoneModal,
  getMileStones,
}) => {
  const mounted = useMounted();
  const [milestoneTypes, setMilestoneTypes] = useState([]);
  const [stages, setStages] = useState([]);

  useEffect(async () => {
    const res = await getMilestoneTypes();
    if (res?.data?.success) {
      setMilestoneTypes(res.data.data.milestoneTypes);
    }

    const stageRes = await getStage();
    if (stageRes?.data?.success) {
      setStages(stageRes.data.data.stages);
    }
  }, []);

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={showAddMilestoneModal}
        onClose={() => setShowAddMilestoneModal(false)}
        maxWidth={"md"}
      >
        <Formik
          initialValues={{
            name: "",
            type: "",
            color: "#222222",
            notes: "",
            budget: "",
            actual: "",
            start_date: new Date(),
            end_date: new Date(),
            stage: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required("Milestone Name is required"),
            type: Yup.string().max(255).required("Milestone Type is required"),
            color: Yup.string()
              .max(255)
              .required("Milestone color is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const res = await addNewMilestone({
                mile_name: values.name,
                mile_type: values.type,
                proj_id: selectedProject.id,
                mile_color: values.color,
                mile_notes: values.notes,
                mile_budget: values.budget,
                mile_actual: values.actual,
                mile_start: moment(values.start_date).format("YYYY-MM-DD"),
                mile_end: moment(values.end_date).format("YYYY-MM-DD"),
                stage: values.stage,
              });
              console.log(res.data);
              if (res.data.success) {
                toast.success(res.data.message);
                if (getMileStones) {
                  getMileStones();
                }
                setShowAddMilestoneModal(false);
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Create New Milestone</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Milestone Name"
                      required
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      {milestoneTypes.length > 0 && (
                        <FormControl
                          className={classes.formControl}
                          error={Boolean(touched.type && errors.type)}
                          required
                        >
                          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                            Milestone Type
                          </InputLabel>
                          <Select
                            labelId="select-status"
                            label="Milestone Type"
                            name="type"
                            value={values.type}
                            fullWidth
                            placeholder="Status"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <MenuItem value="" disabled></MenuItem>
                            {milestoneTypes.map((mileType) => (
                              <MenuItem key={mileType.id} value={mileType.id}>
                                {mileType.type}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {touched.type && errors.type}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Milestone color"
                      type="color"
                      required
                      error={Boolean(touched.color && errors.color)}
                      fullWidth
                      helperText={touched.color && errors.color}
                      margin="normal"
                      name="color"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.color}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Notes"
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      margin="normal"
                      name="notes"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Budget"
                      error={Boolean(touched.budget && errors.budget)}
                      fullWidth
                      helperText={touched.budget && errors.budget}
                      margin="normal"
                      name="budget"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.budget}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Actual"
                      type="number"
                      error={Boolean(touched.actual && errors.actual)}
                      fullWidth
                      helperText={touched.actual && errors.actual}
                      margin="normal"
                      name="actual"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.actual}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      required
                      error={!values.start_date}
                    >
                      <InputLabel id="demo-simple-select-label" shrink>
                        Start Date
                      </InputLabel>
                      <DatePicker
                        label="Start Date"
                        autoOk={true}
                        className="pt-dtpicker"
                        fullWidth
                        onChange={(date) => {
                          setFieldValue("start_date", date);
                        }}
                        value={values.start_date}
                        name="start_date"
                        renderInput={(inputProps) => (
                          <TextField
                            className="pt-datepicker-ip"
                            fullWidth
                            variant="outlined"
                            {...inputProps}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      required
                      error={Boolean(touched.end_date && errors.end_date)}
                    >
                      <InputLabel id="demo-simple-select-label" shrink>
                        End Date
                      </InputLabel>
                      <DatePicker
                        label="End Date"
                        autoOk={true}
                        className="pt-dtpicker"
                        fullWidth
                        onChange={(date) => {
                          setFieldValue("end_date", date);
                        }}
                        value={values.end_date}
                        name="end_date"
                        renderInput={(inputProps) => (
                          <TextField
                            className="pt-datepicker-ip"
                            fullWidth
                            variant="outlined"
                            {...inputProps}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      {stages.length > 0 && (
                        <FormControl className={classes.formControl}>
                          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                            Stage
                          </InputLabel>
                          <Select
                            labelId="select-status"
                            label="Stage"
                            name="stage"
                            value={values.stage}
                            fullWidth
                            placeholder="Stage"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <MenuItem value="" disabled>
                              None
                            </MenuItem>
                            {stages.map((stage) => (
                              <MenuItem key={stage.id} value={stage.id}>
                                {stage.stage}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {touched.stage && errors.stage}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowAddMilestoneModal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddNewMilestoneModal;
