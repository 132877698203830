import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DatePicker from "@mui/lab/DatePicker";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { addTimeLog, getTaskTimeLog } from "../../../api/project/Project";
import useAuth from "../../../hooks/useAuth";
import useLoader from "../../../hooks/useLoader";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const TaskTiming = ({
  task,
  showTaskTiming,
  setShowTaskTiming,
  selectedProject,
}) => {
  const { user } = useAuth();

  const [timeLogs, setTimeLogs] = useState([]);
  const [taskStartDate, setTaskStartDate] = useState(new Date(task.start_date));
  const [taskEndDate, setTaskEndDate] = useState(new Date(task.end_date));

  const [selectedDate, setSelectedDate] = useState(new Date(task.start_date));

  const classes = useStyles();

  useEffect(() => {
    getTimeLog();
  }, []);

  const getTimeLog = async () => {
    loaderStart();
    const res = await getTaskTimeLog(task.id);
    if (res.data.success) {
      setTimeLogs(res.data.taskTimeLogs);
    }
    loaderEnd();
  };

  const { loaderStart, loaderEnd } = useLoader();

  return (
    <>
      <Dialog
        maxWidth={"md"}
        open={showTaskTiming}
        onClose={() => setShowTaskTiming(false)}
        sx={{ minWidth: "350px" }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            minWidth: "350px",
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          {timeLogs.length > 0 ? (
            timeLogs.map((timeLog) => (
              <Box
                sx={{
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #333",
                }}
              >
                <Typography>{timeLog.log_date}</Typography>
                <Typography>{timeLog.hours_logged} hrs</Typography>
              </Box>
            ))
          ) : (
            <Box>
              <Typography align="center">No time log found</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            mx: 3,
            my: 2,
            minWidth: "350px",
          }}
        >
          <Box
            sx={{
              p: 3,
              pt: 6,
              backgroundColor: "#eee",
            }}
          >
            <Formik
              enableReinitialize={true}
              initialValues={{
                totalHours: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                totalHours: Yup.number()
                  .typeError("Total hours must be a number")
                  .max(24)
                  .required("Total Hours is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                loaderStart();
                try {
                  const res = await addTimeLog({
                    taskId: task.id,
                    date: moment(selectedDate).format("YYYY/MM/DD/"),
                    timeLogged: values.totalHours,
                  });
                  if (res.data.success) {
                    toast.success("Time log added");
                    getTimeLog();
                  } else if (res.status === 200) {
                    toast.error(res.data.message);
                  }
                  loaderEnd();
                } catch (err) {
                  console.error(err);
                  toast.error("Can't able to add time log");
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                  loaderEnd();
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item sm={12} md={4}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        required
                        error={!selectedDate}
                      >
                        <InputLabel id="demo-simple-select-label" shrink>
                          Select Day
                        </InputLabel>
                        <DatePicker
                          autoOk={true}
                          className="pt-dtpicker"
                          label={" Select Day  "}
                          minDate={taskStartDate}
                          maxDate={taskEndDate}
                          fullWidth
                          onChange={(date) => setSelectedDate(date)}
                          value={selectedDate}
                          name="selectedDate"
                          renderInput={(inputProps) => (
                            <TextField
                              className="pt-datepicker-ip"
                              fullWidth
                              variant="outlined"
                              {...inputProps}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <TextField
                        label="Total Hours For The Day "
                        required
                        error={Boolean(touched.totalHours && errors.totalHours)}
                        fullWidth
                        helperText={touched.totalHours && errors.totalHours}
                        margin="normal"
                        name="totalHours"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.totalHours}
                        variant="outlined"
                        sx={{ mt: 0 }}
                      />
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{ py: 1.8 }}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default TaskTiming;
