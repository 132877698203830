import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DatePicker from "@mui/lab/DatePicker";
import { Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import issueStatus from "../../../@const/issueStatus";
import { addNewIssue } from "../../../api/project/Project";
import useMounted from "../../../hooks/useMounted";
import FileUploader from "../../FileUploader";
import SelectUserDropdown from "../../SelectUserDropdown";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const AddNewIssueModal = ({
  selectedProject,
  showAddIssueodal,
  setShowAddIssueodal,
  getIssues,
}) => {
  const mounted = useMounted();
  const [taskStartDate, setTaskStartDate] = useState(new Date());
  const [taskEndDate, setTaskEndDate] = useState(new Date());
  const [selectedFile, setSelectedFile] = useState(null);

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={showAddIssueodal}
        onClose={() => setShowAddIssueodal(false)}
        maxWidth={"md"}
      >
        <Formik
          initialValues={{
            name: "",
            status: "",
            notes: "",
            assigned_to: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required("Issue Name is required"),
            status: Yup.string().max(255).required("Issue status is required"),
            assigned_to: Yup.string(),
            notes: Yup.string()
              .max(255)
              .required("Issue Description is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              let data = new FormData();
              data.append("task_doc", selectedFile);
              data.append("issue_name", values.name);
              data.append("issue_status", values.status);
              data.append(
                "issue_start",
                moment(taskStartDate).format("YYYY/MM/DD/")
              );
              data.append(
                "issue_end",
                moment(taskEndDate).format("YYYY/MM/DD/")
              );
              data.append("issue_assignee", values.assigned_to);
              data.append("proj_id", selectedProject.id);
              data.append("task_id", "");
              data.append("issue_desc", values.notes);
              const res = await addNewIssue(data);
              // const res = await addNewIssue({
              //   issue_name: values.name,
              //   issue_status: values.status,
              //   issue_start: moment(taskStartDate).format("YYYY/MM/DD/"),
              //   issue_end: moment(taskEndDate).format("YYYY/MM/DD/"),
              //   issue_assignee: values.assigned_to,
              //   proj_id: selectedProject.id,
              //   task_id: "",
              //   issue_desc: values.notes,
              // });

              if (res.data.success) {
                toast.success("New issue added");
                getIssues();
                setShowAddIssueodal(false);
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Create New Issue</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Issue Name"
                      required
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: "100%" }}>
                      {issueStatus.length > 0 && (
                        <FormControl
                          className={classes.formControl}
                          error={Boolean(touched.status && errors.status)}
                          required
                        >
                          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                            Status
                          </InputLabel>
                          <Select
                            labelId="select-status"
                            label="Status"
                            name="status"
                            value={values.status}
                            fullWidth
                            placeholder="Status"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {issueStatus.map((status) => (
                              <MenuItem key={status.id} value={status.id}>
                                {status.type}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {touched.status && errors.status}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <SelectUserDropdown
                      setSelectedUser={(user_id) => {
                        values.assigned_to = user_id.id;
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <DatePicker
                      label="Start Date *"
                      autoOk={true}
                      className="pt-dtpicker"
                      fullWidth
                      onChange={(date) => {
                        setTaskEndDate(date);
                        setTaskStartDate(date);
                      }}
                      value={taskStartDate}
                      name="taskStartDate"
                      renderInput={(inputProps) => (
                        <TextField
                          className="pt-datepicker-ip"
                          fullWidth
                          variant="outlined"
                          {...inputProps}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <DatePicker
                      label="End Date *"
                      autoOk={true}
                      className="pt-dtpicker"
                      fullWidth
                      onChange={(date) => setTaskEndDate(date)}
                      value={taskEndDate}
                      name="taskEndDate"
                      minDate={taskStartDate}
                      renderInput={(inputProps) => (
                        <TextField
                          className="pt-datepicker-ip"
                          fullWidth
                          variant="outlined"
                          {...inputProps}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      required
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      margin="normal"
                      name="notes"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                      variant="outlined"
                      sx={{ mt: 0 }}
                      multiline
                      minRows={2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileUploader
                      onFileSelectSuccess={(file) => setSelectedFile(file)}
                      onFileSelectError={({ error }) => alert(error)}
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowAddIssueodal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddNewIssueModal;
