import { Box } from "@mui/material";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { getAllMonthlyBudgetData } from "../../../../api/budget/Budget";
import AddButtonRenderer from "./AddButtonRenderer";
import CompleteBudgetDetailedView from "./CompleteBudgetDetailedView";
import ShowAddMonthlyBudgetDetailsModal from "./ShowAddMonthlyBudgetDetailsModal";
import ShowViewOnlyMonthlyBudget from "./ShowViewOnlyMonthlyBudget";
function currencyFormatter(currency) {
  let sansDec = Math.round(currency) + "";
  let formatted = sansDec?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${formatted}`;
}
const Budget = ({
  monthWiseBudgets,
  updateRecord,
  total,
  projectStartDate,
  projectDueDate,
  selectedProject,
  completeBudgetViewOnlyModal,
  setCompleteBudgetViewOnlyModal,
  updateTotalActualOfAMonth,
  showOnlyDetailedView,
  shared,
}) => {
  const gridRef = useRef();
  const { shareId } = useParams();

  const [
    monthlyBudgetDetailsSelectedDateForCreate,
    setMonthlyBudgetDetailsSelectedDateForCreate,
  ] = useState(null);
  const [
    monthlyBudgetDetailsSelectedDateForView,
    setMonthlyBudgetDetailsSelectedDateForView,
  ] = useState(null);
  const [
    showAddMonthlyBudgetDetailsModal,
    setShowAddMonthlyBudgetDetailsModal,
  ] = useState(false);
  const [viewOnlyModal, setViewOnlyModal] = useState(false);
  const [allMonthsDetailedBudget, setAllMonthsDetailedBudget] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [colDefs, setColDefs] = useState([
    {
      field: "actions",
      headerName: "Action",
      width: 105,
      minWidth: 105,
      sortable: false,
      filter: false,
      suppressMenu: true,
      pinned: "left",
      suppressSizeToFit: true,
      cellRendererFramework: (params) =>
        !params.node.rowPinned && (
          <AddButtonRenderer
            params={params}
            setDate={setMonthlyBudgetDetailsSelectedDateForCreate}
            setViewOnlyDate={setMonthlyBudgetDetailsSelectedDateForView}
          />
        ),
    },
    { field: "date", headerName: "Date" },
    {
      field: "budget",
      headerName: "Budget ($)",
      editable: false,
      valueFormatter: (params) => currencyFormatter(params.data.budget),
    },
    {
      field: "actual",
      headerName: "Actual ($)",
      editable: false,
      valueFormatter: (params) => currencyFormatter(params.data.actual),
    },
    {
      colId: "variance",
      headerName: "Variance ($)",
      valueFormatter: (params) => currencyFormatter(params),
      cellRenderer: (params) => {
        const difference = params.data.budget - params.data.actual;
        const diff = currencyFormatter(Math.abs(difference));
        const render = difference < 0 ? `(${diff})` : diff;
        let className = "black";
        if (difference > 0) {
          className = "green";
        } else if (difference < 0) {
          className = "red";
        }
        return `<span class="${className}">${render}</span>`;
      },
    },
    {
      colId: "percentage",
      headerName: "Percentage",
      cellRenderer: (params) => {
        let className = "black";
        const actual = params.data.actual;
        const budget = params.data.budget;
        if (budget === 0) {
          return `<span class="${className}">0%</span>`;
        }

        const percentage = (actual / budget) * 100;
        const diff = budget - actual;
        if (diff > 0) {
          className = "green";
        } else if (diff < 0) {
          className = "red";
        }
        return `<span class="${className}">${percentage.toFixed(2)}%</span>`;
      },
    },
    {
      field: "note",
      headerName: "Note",
      editable: true,
      minWidth: 200,
      cellEditorPopup: true,
      cellEditor: "agLargeTextCellEditor",
      tooltipField: "note",
      cellEditorParams: {
        maxLength: 100000,
      },
    },
  ]);

  const [pinnedData, setPinnedData] = useState([]);

  useEffect(() => {
    getAllMonthsDetailedBudget();
  }, []);

  useEffect(() => {
    if (monthlyBudgetDetailsSelectedDateForCreate) {
      setShowAddMonthlyBudgetDetailsModal(true);
    } else {
      setShowAddMonthlyBudgetDetailsModal(false);
    }
  }, [monthlyBudgetDetailsSelectedDateForCreate]);

  useEffect(() => {
    if (monthlyBudgetDetailsSelectedDateForView) {
      setViewOnlyModal(true);
    } else {
      setViewOnlyModal(false);
    }
  }, [monthlyBudgetDetailsSelectedDateForView]);

  useEffect(() => {
    setPinnedData([
      {
        date: `Summary: ${moment(projectDueDate).diff(
          moment(projectStartDate),
          "month"
        )}`,
        budget: total.budget,
        actual: total.actual,
        variance: total.budget - total.actual,
        percentage: (total.actual / total.budget) * 100,
      },
    ]);
  }, [total, projectStartDate, projectDueDate]);

  useEffect(() => {
    if (gridApi && pinnedData) {
      gridApi.setPinnedTopRowData(pinnedData);
    }
  }, [pinnedData]);

  useEffect(() => {
    gridApi && gridApi.setRowData(monthWiseBudgets);
    gridApi && gridApi.setPinnedTopRowData(pinnedData);
  }, [monthWiseBudgets]);

  const onGridReady = useCallback(async (params) => {
    params.api.setRowData(monthWiseBudgets);
    params.api.setPinnedTopRowData(pinnedData);
    agGridEvent(params);
  }, []);

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const agGridEvent = (props) => {
    setGridApi(props.api);
    props.api.addEventListener("cellValueChanged", (event) =>
      updateRecord(event)
    );
  };

  const getAllMonthsDetailedBudget = async () => {
    const response = await getAllMonthlyBudgetData(
      selectedProject.id,
      shared,
      shareId
    );
    if (response.status === 200) {
      setAllMonthsDetailedBudget(response.data.data.items);
    }
  };

  const onSuccess = (
    budgetId,
    budget,
    actual,
    date,
    isDeleted = false,
    newData = false
  ) => {
    const filteredData = allMonthsDetailedBudget.filter(
      (item) => item.date === date
    );
    let totalActual = 0;
    let totalBudget = 0;
    let budgetIdExists = false;
    // Replace filtered data with new actual where budgetId matches
    filteredData.forEach((item) => {
      if (item.id === budgetId) {
        budgetIdExists = true;
        if (isDeleted) {
          totalActual += 0;
          totalBudget += 0;
        } else {
          totalActual += actual;
          totalBudget += budget;
        }
      } else {
        totalActual += item.actual;
        totalBudget += item.budget;
      }
    });

    // If new data is added, add it to totalActual
    if (newData && !budgetIdExists) {
      totalActual += actual;
      totalBudget += budget;
    }

    updateTotalActualOfAMonth(date, totalActual, totalBudget);
    getAllMonthsDetailedBudget();
  };

  return (
    <>
      {}
      {showAddMonthlyBudgetDetailsModal && (
        <ShowAddMonthlyBudgetDetailsModal
          date={monthlyBudgetDetailsSelectedDateForCreate}
          showModal={showAddMonthlyBudgetDetailsModal}
          setShowModal={setShowAddMonthlyBudgetDetailsModal}
          setDate={setMonthlyBudgetDetailsSelectedDateForCreate}
          selectedProject={selectedProject}
          onSuccess={onSuccess}
        />
      )}
      {viewOnlyModal && (
        <ShowViewOnlyMonthlyBudget
          data={monthlyBudgetDetailsSelectedDateForView}
          monthWiseBudgets={monthWiseBudgets}
          setData={setMonthlyBudgetDetailsSelectedDateForView}
          allBudget={allMonthsDetailedBudget}
          selectedProject={selectedProject}
          showModal={viewOnlyModal}
          setShowModal={setViewOnlyModal}
          getAllMonthsDetailedBudget={onSuccess}
          updateTotalActualOfAMonth={updateTotalActualOfAMonth}
        />
      )}
      {completeBudgetViewOnlyModal && (
        <CompleteBudgetDetailedView
          monthWiseBudgets={monthWiseBudgets}
          data={monthlyBudgetDetailsSelectedDateForView}
          setData={setMonthlyBudgetDetailsSelectedDateForView}
          allBudget={allMonthsDetailedBudget}
          selectedProject={selectedProject}
          showModal={completeBudgetViewOnlyModal}
          setShowModal={setCompleteBudgetViewOnlyModal}
          getAllMonthsDetailedBudget={getAllMonthsDetailedBudget}
        />
      )}
      {!showOnlyDetailedView ? (
        <Box
          sx={{
            width: "100%",
            height: "70vh",
          }}
        >
          <div className="example-wrapper">
            <div
              id="myGrid"
              style={{
                height: "100%",
                width: "100%",
              }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                defaultColDef={{
                  flex: 1,
                  minWidth: 60,
                  sortable: true,
                  resizable: true,
                  filter: true,
                }}
                ref={gridRef}
                autoGroupColumnDef={{ minWidth: 60 }}
                sideBar={false}
                onGridReady={onGridReady}
                columnDefs={colDefs}
                enableRangeSelection={true}
                enableCharts={true}
                popupParent={popupParent}
                suppressAggFuncInHeader={true}
                pinnedTopRowData={pinnedData}
              ></AgGridReact>
            </div>
          </div>
        </Box>
      ) : (
        <>
          <CompleteBudgetDetailedView
            monthWiseBudgets={monthWiseBudgets}
            data={monthlyBudgetDetailsSelectedDateForView}
            setData={setMonthlyBudgetDetailsSelectedDateForView}
            allBudget={allMonthsDetailedBudget}
            selectedProject={selectedProject}
            showModal={true}
            setShowModal={() => {}}
            getAllMonthsDetailedBudget={getAllMonthsDetailedBudget}
            showWithoutModal={true}
          />
        </>
      )}
    </>
  );
};

export default Budget;
