import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink, useParams } from "react-router-dom";
import { getSinglePortfolio } from "../../api/portfolio/Portfolio";
import Dashboard from "../../components/dashboard/portfolios/Dashboard";
import ViewAndEditPortfolioForm from "../../components/dashboard/portfolios/ViewAndEditPortfolioForm";
import PermissionGuard from "../../components/PermissionGuard";
import useCommons from "../../hooks/useCommons";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
const tabs = [
  { label: "Dashboard", value: "dashboard" },
  { label: "Portfolio Details", value: "portfolioDetails" },
];

const ViewAndEditPortfolio = () => {
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const activeTabParam = parameters.get("p");

  const [currentTab, setCurrentTab] = useState(activeTabParam || "dashboard");
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [editActive, setEditActive] = useState(false);

  const { portfolioId } = useParams();
  const { updateCurrentPath } = useCommons();
  const { settings } = useSettings();

  useEffect(() => {
    getSinglePortfolioDetails();
  }, []);

  useEffect(() => {
    if (selectedPortfolio) {
      if (selectedPortfolio.project_count === 0) {
        setCurrentTab("portfolioDetails");
      }
    }
  }, [selectedPortfolio]);

  const handleTabsChange = (event, value) => {
    window.history.pushState(
      null,
      null,
      `/dashboard/portfolios/view-and-edit-portfolio/${portfolioId}?p=${value}`
    );
    updateCurrentPath();
    setCurrentTab(value);
  };

  const getSinglePortfolioDetails = async () => {
    try {
      const response = await getSinglePortfolio(portfolioId);
      const portfolioDetails = response.data.data.portfolio;
      setSelectedPortfolio(portfolioDetails);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Portfolio</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/dashboard/portfolios/view-all-portfolios"
                  variant="breadCrumbText"
                >
                  Portfolios
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  {selectedPortfolio?.name}
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                {selectedPortfolio?.name}
              </Typography>
            </Grid>
            <Grid item>
              {currentTab === "portfolioDetails" && (
                <Box sx={{ m: -1 }}>
                  <PermissionGuard
                    permissionCheck="PORTFOLIO_UPDATE"
                    returnEmpty={true}
                  >
                    {!editActive && (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                          setEditActive(true);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </PermissionGuard>
                </Box>
              )}
            </Grid>
          </Grid>
          {selectedPortfolio && (
            <Card sx={{ mt: 2 }}>
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
                sx={{
                  ".MuiTab-wrapper": {
                    textTransform: "none",
                    fontSize: "12px",
                  },
                  ".Mui-selected": {
                    fontWeight: "bold",
                    color: "#000",
                  },
                  button: {
                    minWidth: "80px",
                    "&:hover": {
                      backgroundColor: "#eee",
                    },
                  },
                  ".MuiTabs-indicator": {
                    minWidth: "60px",
                  },
                  px: 2,
                }}
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
              <Card sx={{ p: 2 }}>
                {currentTab === "dashboard" && (
                  <Dashboard
                    editActive={editActive}
                    setEditActive={setEditActive}
                    selectedPortfolio={selectedPortfolio}
                  />
                )}
                {currentTab === "portfolioDetails" && (
                  <ViewAndEditPortfolioForm
                    editActive={editActive}
                    setEditActive={setEditActive}
                    selectedPortfolio={selectedPortfolio}
                  />
                )}
              </Card>
            </Card>
          )}
        </Container>
      </Box>
    </>
  );
};

export default ViewAndEditPortfolio;
