import {
  Box,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { InsertDriveFile, Fullscreen, Close } from "@mui/icons-material";
import { useState, useRef, useEffect } from "react";
import { getAllTemplates } from "../api/admin/Template";

const TemplatePopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [templates, setTemplates] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleTemplateClick = (url) => {
    setIframeUrl(url);
    setDialogOpen(true);
    setOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setIframeUrl("");
    setIsFullscreen(false);
  };

  const toggleFullscreen = () => setIsFullscreen(!isFullscreen);

  const fetchTemplates = async () => {
    const res = await getAllTemplates();
    if (res.data) {
      setTemplates(res.data);
    }
  };

  return (
    <>
      <Tooltip title=" Templates">
        <IconButton
          size="small"
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <InsertDriveFile fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{
          sx: { width: 250 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            Excel Templates
          </Typography>
        </Box>
        <List>
          {templates.map((template) => (
            <ListItem
              button
              key={template.name}
              onClick={() => handleTemplateClick(template.url)}
            >
              <ListItemText primary={template.name} />
            </ListItem>
          ))}
        </List>
      </Popover>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullScreen={isFullscreen}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {isFullscreen ? "Template (Fullscreen)" : "Template"}
        </DialogTitle>
        <DialogContent>
          <iframe
            title="Excel Templates"
            src={iframeUrl}
            width="100%"
            height="600px"
            style={{
              border: "none",
              height: isFullscreen ? "100vh" : "600px",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleFullscreen}
            color="primary"
            startIcon={<Fullscreen />}
          >
            {isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
          </Button>
          <Button
            onClick={handleDialogClose}
            color="secondary"
            startIcon={<Close />}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TemplatePopover;
