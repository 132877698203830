import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  InputAdornment,
  TableBody,
  TextField,
  Typography,
  Grid,
  Pagination,
} from "@mui/material";
import SearchIcon from "../../../icons/Search";
import SingleCredentialInfo from "./SingleCredentialInfo";
import roles from "../../../@const/roles";
import useTable from "../../../hooks/useTable";
import useAuth from "../../../hooks/useAuth";
import useLoader from "../../../hooks/useLoader";

const ViewAllCredentialsListTable = (props) => {
  const { allUsers, handleAllUser, handleAdminRequest, ...other } = props;

  const { loaderState } = useLoader();
  const { user } = useAuth();

  const [currentPage, setCurrentPage] = useState(1);
  const [filterUserRole, setFilterUserRole] = useState(0);
  const [nameSearchValue, setNameSearchValue] = useState("");

  const headCells =
    user.role === 1
      ? [
          { id: "slNo", label: "#" },
          { id: "first_name", label: "NAME" },
          { id: "role_name", label: "ROLE", renderCondition: true },
          { id: "email", label: "	EMAIL" },
          { id: "company_name", label: "	COMPANY NAME" },
          { id: "code", label: "	COMPANY CODE" },
          { id: "created_at", label: "DATE CREATED" },
          { id: "is_authorized", label: "	SYSTEM ACCESS" },
          {
            id: "actions",
            label: "ACTIONS",
            align: "right",
            disableSorting: true,
          },
        ]
      : [
          { id: "slNo", label: "#" },
          { id: "first_name", label: "NAME" },
          { id: "role_name", label: "ROLE", renderCondition: true },
          { id: "email", label: "	EMAIL" },
          { id: "created_at", label: "DATE CREATED" },
          { id: "is_authorized", label: "	SYSTEM ACCESS" },
          {
            id: "actions",
            label: "ACTIONS",
            align: "right",
            disableSorting: true,
          },
        ];

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    allUsers?.data,
    headCells,
    +filterUserRole,
    0
  );

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleUserRoleChange = (e) => {
    setCurrentPage(1);
    setFilterUserRole(e.target.value);
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setNameSearchValue(e.target.value);
  };

  useEffect(() => {
    let userRole;
    if (filterUserRole === 0) {
      userRole = "";
    } else {
      userRole = filterUserRole;
    }
    handleAllUser(currentPage, userRole, nameSearchValue);
  }, [currentPage, filterUserRole, nameSearchValue]);

  const refreshUserList = () => {
    let userRole;
    if (filterUserRole === 0) {
      userRole = "";
    } else {
      userRole = filterUserRole;
    }
    handleAllUser(currentPage, userRole, nameSearchValue);
  };

  return (
    <Card {...other}>
      <Box
        sx={{
          m: -1,
          p: 3,
          display: props.showOnlyNewUsers ? "none" : "block",
        }}
      >
        <Typography
          variant="dashboardContentHeader"
          sx={{
            color: "#2D3E56",
            mb: "18px",
            display: "block",
          }}
        >
          User Access Level Settings
        </Typography>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              label="Filter Users"
              fullWidth
              name="status"
              onChange={handleUserRoleChange}
              select
              SelectProps={{ native: true }}
              value={filterUserRole || "all"}
              variant="outlined"
              placeholder="Filter Users"
            >
              <option
                aria-label="None"
                value={0}
                selected={filterUserRole === 0}
              >
                All
              </option>
              {roles.map((role) => (
                <option
                  key={role.roleType}
                  value={role.roleType}
                  selected={filterUserRole === role.roleType}
                >
                  {role.roleValue}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              label="Search User"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={searchHandler}
              placeholder="Enter user name"
              value={nameSearchValue}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 240,
          }}
        />
      </Box>
      <Box sx={{ pt: 3 }}>
        <TblContainer>
          <TblHead />
          {allUsers?.data?.length > 0 && (
            <TableBody>
              {recordsAfterPagingAndSorting()
                .filter((user) => {
                  if (!props?.showOnlyNewUsers) return true;
                  console.log(user);
                  if (user.role.role_name !== "Admin") return false;
                  const createdAtDate = new Date(user.created_at);
                  const twoMonthsAgo = new Date();
                  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
                  return createdAtDate >= twoMonthsAgo;
                })
                .map((user, index) => {
                  return (
                    <SingleCredentialInfo
                      filterUserRole={filterUserRole}
                      user={user}
                      key={index}
                      index={index}
                      handleAdminRequest={handleAdminRequest}
                      refreshUserList={refreshUserList}
                      handleAllUser={handleAllUser}
                      currentPage={currentPage}
                      nameSearchValue={nameSearchValue}
                    />
                  );
                })}
            </TableBody>
          )}
        </TblContainer>
        <Box
          sx={{
            py: 4,
            display: props?.showOnlyNewUsers ? "none" : "flex",
            justifyContent: "center",
          }}
        >
          {allUsers?.data?.length > 0 ? (
            <Pagination
              page={currentPage}
              onChange={handleChange}
              count={allUsers.last_page}
            />
          ) : (
            <Typography variant="h6" color="secondary">
              {loaderState ? "Loading..." : "No Records Available"}
            </Typography>
          )}
        </Box>
      </Box>
    </Card>
  );
};

ViewAllCredentialsListTable.propTypes = {
  allUsers: PropTypes.array.isRequired,
};

export default ViewAllCredentialsListTable;
